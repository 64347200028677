@import "src/styles/lib";

.card {
  background: $lighter-blue;
  width: 100%;
  border-radius: 10px;
  height: 247px;
  display: flex;
  align-items: center;
  justify-content: center;
  .card-content {
    text-align: center;
    // padding: 50px;
    .icon {
      img{
        width: 63px;
        height: 63px;
      }
    }

    .title {
      color: $black;
      font-weight: 600;
      font-size: 40px;
      line-height: 50.4px;
    }

    .description {
      color: $black;
      font-weight: 400;
      font-size: 18px;
      line-height: 23px;
    }
  }
  &:hover {
    background: $light-yellow;
    box-shadow: -1px 23px 48px -28px rgba(0, 0, 0, 0.75);
    transition: 0.5s all;
    //         background: rgba(69, 60, 41, 0.4);
    // filter: blur(32px);
    // border-radius: 6.88136px;
  }
}

.card-secondary {
  background: $heavier-yellow;
  width: 100%;
  border-radius: 10px;
  height: 700px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  .card-content {
    text-align: center;
    // padding: 50px;

    .icon {
      padding: 20px 0 0 0;
      img {
        width: 286.5px;
        height: 231.99px;
      }
    }
    .title {
      color: $black;
      font-weight: 600;
      font-size: 22px;
      line-height: 28px;
      padding: 10% 0;
    }

    .description {
      color: $black;
      font-weight: 400;
      font-size: 16px;
      line-height: 27.2px;
      text-align: center;
      padding: 0 10px;
      height: 300px;
    }
  }
  &:hover {
    background: $light-yellow;
    box-shadow: -1px 23px 48px -28px rgba(0, 0, 0, 0.75);
    transition: 0.5s all;
  }
}

.card-third {
  background: #FFF56A66;
  // height: 488px;
  border-radius: 10px;
  position: relative;
  width: 100%;
  .card-header {
    img{
      position: absolute; 
      top: -10%;
      right: -5%;
    }
  }
  .card-footer {
    img{
      position: absolute;
      left: -5%;
      bottom: -10%;
    }
  
  }
  .card-content {

    // padding: 50px;
    display: flex;
    display: block;
    padding: 3%;
    .style{
      text-align: left;
      padding: 1% 1%;
      font-weight: 400;
    }
    .icon {
      height: 260px;
      img {
        width: 286.5px;
        height: 231.99px;
      }
    }
    .title {
      color: $black;
      font-weight: 600;
      font-size: 22px;
      line-height: 28px;
      padding: 10% 0;
    }
    .sub-header{
      color: $black;
      font-weight: 600;
      font-size: 16px;
      color: #150F1E;
      font-style: normal;
      width: 100%
    }
    .description {
      color: $black;
      font-weight: 400;
      font-size: 16px;
      color: #150F1E;
      font-style: normal;
      width: 100%
    }
    .author{
      color: $black;
      font-weight: 600;
      font-size: 16px;
      color: #150F1E;
      font-style: normal;
      width: 100%
    }
  }
  &:hover {
    background: $light-yellow;
    box-shadow: -1px 23px 48px -28px rgba(0, 0, 0, 0.75);
    transition: 0.5s all;
  }
}

.card-wrapper{
  text-align: center;
 
  width: 100px;
  .description {
    color: $white;
    font-weight: 400;
    font-size: 12px;
    line-height: 23px;
    margin-top: 10px;
    font-weight: bold;
  }
.card-fourth {
  background: $heavier-yellow;
  width: 100px;
  border-radius: 10px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 5px 5px 0px 0px #000000;
  .card-content {
    text-align: center;
    // padding: 50px;
    .icon {
      img{
        width: 63px;
        height: 63px;
      }
    }

  
  }
  &:hover {
    background: $light-yellow;
    box-shadow: -1px 23px 48px -28px rgba(0, 0, 0, 0.75);
    transition: 0.5s all;
    //         background: rgba(69, 60, 41, 0.4);
    // filter: blur(32px);
    // border-radius: 6.88136px;
  }
}
}

.card-link{
  display: flex;
  justify-content: flex-end;
  font-size: 13px;
  font-style: normal;
font-weight: 600;
text-decoration: underline;
cursor: pointer;
}

.card-href{
  font-size: 13px;
  font-style: normal;
font-weight: 600;
text-decoration: underline;
cursor: pointer;
position: absolute;
bottom: 2%;
left: 40%;
}

.card-content{
  padding: 10px;
  .children{
    color: #150F1E;

    .description{
      color: $black;
      line-height: 19px;
      font-size: 14px;
      text-align: left;
      font-weight: 400;
      // padding: 20px;
    }
    ul{
      padding: 0 0 20px 35px;
    }
    .card-heading{
      text-align: left;
      font-size: 15px;
      font-weight: bold;
      padding-top: 40px;
      padding-bottom: 10px;
      line-height: 20px;
    }
    .np{
      padding-top: 0;
    }
    .pt{
      padding-top: 30px;
    }
  }
}

@media screen and (max-width: 899px) {
  .card-third {
    .card-footer{
      display: none;
    }
    .card-header{
      display: none
    }
  }
}



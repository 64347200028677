@import "src/styles/lib";

footer{
    background: #000;
    width: 100%;
    height:100%;
    display:flex;
    padding: 5% 0;
    justify-content: center;
    img{
      width: 58.32px!important;
      height: 58.32px!important;
    }
    a{
      text-decoration: none;
      margin: 0 1%;
    }
  }
@import "mixins";
@import "colors";
@import "fonts";

body {
  h1,
  h2,
  h3,
  h4,
  h5,
  label,
  input,
  p {
    padding: 0;
    margin: 0;
    font-weight: 400;
    font-family: $plus-jakarta-sans;
    font-size: 16px;
    color: $black;
    line-height: em(16px, 24px);
  }

  h1 {
    font-size: rem(24);
    line-height: em(24px, 36px);

    &.text-bold {
      font-weight: 700 !important;
    }
  }

  h2 {
    font-size: rem(20);
    line-height: em(20px, 30px);
  }

  h3 {
    font-size: rem(18);
    line-height: em(18px, 23px);
  }

  h4 {
    font-size: rem(16);
    line-height: em(16px, 16px);
  }

  h5 {
    font-size: rem(14);
    line-height: em(14px, 14px);
  }

  h6 {
    font-size: rem(13);
    line-height: em(13px, 13px);
  }

  @include screen-full-hd {
    font-size: 90%;
  }

  @include screen-hd {
    font-size: 80%;
  }

  @include tablet-big {
    font-size: 70%;
  }

  @include tablet {
    font-size: 60%;
  }
}

$white: #ffffff;
$buttontextwhite: #fffefa;
$black: #000000;
$transparent: transparent;

$main: #000;
$main-lighter: #000;
$main-faded: #baa3e2;
$heavier-yellow: #FFF293;
$light-yellow: #FFE000;
$lighter-blue: #FFF56A66;
$light-blue: #aecbff;
$light-pink: #ffe7f8;
$lighter-pink: #FFE000;
$light-green: #d6fae7;
$background: #FFE000;
@import "src/styles/lib";

.custom-button{
    background: $main-faded;
    color: $white;
    padding: 16px;
    border-radius: 100px;
    border: none;
    gap: 10px;
    box-shadow: 5px 5px 0px 0px #000000;
    cursor: pointer;
    p{
        color: $buttontextwhite;
        font-weight: 700;
        font-size: 20px;
        line-height: 25.2px;
    }
    &:active{
        margin-top: 5px;
        box-shadow: none;
    }
}
@import "src/styles/lib";

.header {
    background: $main;
    height: 82px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
  }

  .logo {
    
    img{
      width:  74.8px;
      height: 46.14px;
    }
        h1 {
          color: $white;
        }
    color: $white;
    justify-self: start;
    margin-left: 30px;
    margin-right:200px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
                font-family: "Plus Jakarta Sans",
                sans-serif;
  }
  
  .fa-firstdraft {
    margin-left: 0.5rem;
    font-size: 1.6rem;
  }
  
  .nav-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: start;
    margin-right: 2rem;
    .mobile{
      display: none;
    }
  }
  


  .nav-item {
    display: flex;
    align-items: center;
    height: 80px;
  }
  
  .nav-links {
    color: $white;
    text-decoration: none;
    padding: 0.5rem 1rem;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 25.2px;
  }
  
  .fa-bars {
    color: $white;
  }
  
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
  }

 .mobile-only {
   display: none;
 }
  @media screen and (max-width: 960px) {
    .logo {
        display: none;
      }
    .NavbarItems {
      position: relative;
    }
  
        .mobile-only {
          display: block;
        }
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 1000px;
      position: absolute;
      top: 66px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
      .mobile{
        display: block;
        .nav-links{
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
  
    .nav-menu.active {
      background: $main-lighter;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 999999;
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }
  
    .logo {

      position: absolute;
      top: 0;
      left: 0;
      transform: translate(25%, 50%);
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }
  
    .nav-links-mobile {
      display: block;
      text-align: center;
      padding: 1.5rem;
      margin: 2rem auto;
      border-radius: 4px;
      width: 80%;
      background: #1888ff;
      text-decoration: none;
      color: #fff;
      font-size: 1.5rem;
    }
  
    .nav-links-mobile:hover {
      background: #fff;
      color: #1888ff;
      transition: 250ms;
    }
  
                                @media screen and (max-width: 962px) {
                                  .logo {
                                    margin-top: 25px;
                                  }
                                }
 
  }
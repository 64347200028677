@import "src/styles/lib";
.heading{
    p{
        font-weight: 700;
        font-size: 45px;
        line-height: 57px;
        letter-spacing: 0.02em;
        color: $black;
    }
}
@media screen and (max-width: 1289px) {
    .heading{
        p{
            font-size: 30px;
        }
    }
}
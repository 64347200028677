@import "src/styles/lib";

.banner {
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}
.banner-header {
  position: absolute;
  img{
    width: 100%
  }
}

@import "src/styles/lib";

.dropdown-menu {
    background: $white;
    width: 300px;
    position: absolute;
    top: 80px;
    list-style: none;
    text-align: start;
    font-size: 16px;
    border-left: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
    z-index: 9999;
  }
  
  .dropdown-menu li {
    background: $white;
    cursor: pointer;
    border-top: 1px solid #ccc;
  }
  
  .dropdown-menu.clicked {
    display: none;
  }
  
  .dropdown-link {
    display: block;
    height: 100%;
    width: 100%;
    text-decoration: none;
    color: $black;
    padding: 16px;
    font-size: 16px;
  }
  
  @media screen and (max-width: 960px) {
    .fa-caret-down {
      display: none;
    }
  }
@import "src/styles/lib";
  .button-container {
    display: flex;
    justify-content: center;
    padding: 3% 0 0 0 ;
  }
    .banner2 {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 100%;
      height: 100vh;
      background-color: #ffe000;
  
      .starsImage {
        position: absolute;
        top: 40%;
        /* Adjust the top value */
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 80%;
        height: auto;
        // animation: mymove 2s infinite alternate;
      }
  
      .textImage {
        position: relative;
        z-index: 1;
        max-width: 90%;
        height: auto;
      }
    }
  
    @media (max-width: 768px) {
      .banner2 {
        height: 100%;
  
        .starsImage {
          max-width: 90%;
        }
  
        .textImage {
          max-width: 100%;
        }
      }
    }
  
                                                                                                                                                                                                                                                                .banner {
    height: 900px;
    background: #FFE000;
      /* Main background color */
      position: relative;
  .gradient-overlay {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 150px;
      /* Adjust height as needed */
      background: linear-gradient(to bottom, rgba(255, 224, 0, 0) 0%, #FFE000 50%, #FFD700 100%);
    }
  
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                .banner-wrapper {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  position: relative;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  z-index: 1;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  /* Ensure content is above the gradient overlay */
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                .banner-content {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  .banner-slider {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    position: absolute;
        left: 0;
        top: 30%;
      }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                .slider-title {
        font-size: 80px;
        line-height: 101px;
        font-weight: 700;
        width: 600px;
        margin-bottom: 10%;
      }
                                                .banner-logo {
        position: absolute;
        right: 15%;
        top: 0;
        display: flex;
          justify-content: center;
          align-items: center;
        
          img {
          width: auto;
            height: 100%;
            max-width: 100%;
        }
      }
    }
  }
  
  // @media screen and (max-width: 962px){
  //   .banner {
  //     height: 700px;
  //     background: $background;
  //     .banner-wrapper{
  //       position: static;
        
  //     }
  //     .banner-content{
       
  //       .banner-slider{
  //         margin-top: -10px;
  //         margin-left: 400px;
  //         text-align: center;
         
  //         .slider-title{
  //           font-size: 26px;
  //           width: 100%;
  //         } 
  //       }
  //       .banner-logo{
  //         // position: absolute;
  //         // right: 0;
  //         // top: 0;
  //         margin-top: 200px;
  //         img{
  //           width: 100%;
  //         }
  //       }
  //     }
  //   }
  // }
  @media screen and (max-width: 1112px){
    .banner {
      height: 700px;
      background: $background;
      .banner-wrapper{
        position: static;
        
      }
      .banner-content{
       
        .banner-slider{
          margin-top: -100px;
          margin-left: 400px;
          text-align: center;
         
          .slider-title{
            font-size: 26px;
            width: 100%;
          } 
        }
        .banner-logo{
          // position: absolute;
          // right: 0;
          // top: 0;
        margin-left: 150px;
          img{
            width: 100%;
          }
        }
      }
    }
  }
  @media screen and (max-width: 901px) {
    .banner {
      height: 600px;
      background: $background;
      .banner-wrapper{
        position: static;
        
      }
      .banner-content{
       
        .banner-slider{
          margin-top: -100px;
          margin-left: 30px;
          text-align: center;
         
          .slider-title{
            // display: flex;
            margin: 0 auto;
            font-size: 26px;
            width: 100%;
          } 
        }
        .banner-logo{
          // position: absolute;
          // right: 0;
          // top: 0;
          margin-top: 50px;
            margin-left: 15%;
          
            img {
              width: 100%;
            }
          }
          }
          }
          }
          
          @media screen and (max-width: 425px) {
.section-image {
    max-height: 250px;
    /* Final adjustment for smallest screens */
  }
            .banner {
              height: 600px;
              background: $background;
          
              .banner-wrapper {
                position: static;
          
              }
          
              .banner-content {
          
                .banner-slider {
                  margin-top: -100px;
                  margin-left: 30px;
                  text-align: center;
          
                  .slider-title {
                    // display: flex;
                    margin: 0 auto;
                    font-size: 26px;
                    width: 100%;
                  }
                }
          
                .banner-logo {
                  // position: absolute;
                  // right: 0;
                  // top: 0;
                  margin-top: 150px;
            margin-left: 15%;
          img{
            width: 100%;
          }
        }
      }
    }
  }

  section{
    width: 100%;
    text-align: center;
    padding: 0 0 3% 0;
   &.background{
     background: $background;
   }
   .heading-container {
    h1{
      padding: 3% 0;
      font-weight: bold;
font-size: 45px;
line-height: 57px;
text-align: center;
    }
   
  }
  .switch-container{
    padding: 3% 0;
  }
  .wrapper {
    border-radius: 37px;
    background-color: $lighter-pink;
    padding: 8px;
    width: 100%;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }

  .tab-switch {
    position: relative;
  }
  
  .tab-switch {
    .active{
      content: "";
      position: absolute;
      width: 50%;
      top: 0;
      transition: left cubic-bezier(0.88, -0.35, 0.565, 1.35) 0.4s;
      border-radius: 27.5px;
      box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
      background-color: $main-lighter;
      height: 100%;
      z-index: 0;
    }
 
  }
  
  .tab-switch.left:after {
    left: 0;
  }
  
  .tab-switch.right:after {
    left: 50%;
  }
  
  .tab-switch .tab {
    display: inline-block;
    width: 50%;
    padding: 20px 0;
    z-index: 1;
    position: relative;
    cursor: pointer;
    transition: color 200ms;
    font-size: 16px;
    font-weight: bold;
    line-height: normal;
    user-select: none;
  }
  
  .tab-switch .tab.active {
    color: #ffffff;
  }
  }
  
  .view-middle-header {
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .view-middle-header-title {
    font-size: 45px;
    font-weight: 700;
    line-height: 56;
  }
  .view-middle-content {
    width: 100%;
    height: 900px;
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
  .view-middle-content-right {
    width: 50%;
    height: 900px;
    display: flex;
    justify-content: center;
    align-items: center;
  }


.view-middle-content-left {

  .view-middle-content-left-card1 {
    margin-bottom: 5%;
    background: $lighter-blue;
    background-color: #000;
    width: 100%;
    border-radius: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: -1px 10px 35px -28px rgba(0, 0, 0, 0.75);

    .view-middle-content-left-card1-content {
      // padding: 50px;
      flex-direction: column;
      display: flex;
      height: 100%;
      margin-left: 2%;
      padding: 10px;
      .icon {
        font-size: 30px;
      }

      .view-moddle-content-left-card1-title {
        color: #fff;
        font-weight: 700;
        font-size: 16px;
        text-align: left;
        margin-bottom: 10px;
        .title {
          font-size: 10px;
        }
      }

      .view-moddle-content-left-card1-desc {
        color: #fff;
        font-weight: 500;
        font-size: 12px;
        line-height: 20.4px;
        text-align: left;
        
      }
  
    }
    &:hover {
      background: #000;
      box-shadow: -1px 23px 48px -28px rgba(0, 0, 0, 0.75);
      transition: 0.5s all;
      //         background: rgba(69, 60, 41, 0.4);
      // filter: blur(32px);
      // border-radius: 6.88136px;
    }
  }
}

.card-link {
  color: #fff;
}
.view-middle-content-left-card2 {
  background: $lighter-blue;
  background-color: #000;
  width: 100%;
  border-radius: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: -1px 10px 35px -28px rgba(0, 0, 0, 0.75);
  margin-bottom: 5%;
  text-align: left;
  .view-middle-content-left-card2-content {
    // padding: 50px;
    flex-direction: column;
    display: flex;
    margin-left: 2%;
    text-align: left;
    height: 100%;
    .icon {
      font-size: 30px;
    }

    .view-moddle-content-left-card2-title {
      color: $black;
      font-weight: 700;
      font-size: 16px;
      margin-bottom: 10px;
      
      .title {
        font-size: 10px;
      }
    }

    .view-moddle-content-left-card2-desc {
      color: $black;
      font-weight: 500;
      font-size: 12px;
      line-height: 20.4px;
      color: #150f1e;
    }
  }
  &:hover {
    background: #000;
    box-shadow: -1px 23px 48px -28px rgba(0, 0, 0, 0.75);
    transition: 0.5s all;
    //         background: rgba(69, 60, 41, 0.4);
    // filter: blur(32px);
    // border-radius: 6.88136px;
  }
}

.view-middle-content-left-card2 {
  background: $lighter-blue;
  background-color: #000;
  width: 100%;
  border-radius: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: -1px 10px 35px -28px rgba(0, 0, 0, 0.75);
  margin-bottom: 5%;
  .view-middle-content-left-card2-content {
    // padding: 50px;
    flex-direction: column;
    display: flex;
    margin-left: 2%;
    padding: 10px;
    height: 100%;
    .icon {
      font-size: 30px;
    }

    .view-moddle-content-left-card2-title {
      color: #fff;
      font-weight: 700;
      font-size: 16px;
      margin-bottom: 10px;
      .title {
        font-size: 10px;
      }
    }

    .view-moddle-content-left-card2-desc {
      color: #fff;
      font-weight: 500;
      font-size: 12px;
      line-height: 20.4px;
    }
  }
  &:hover {
    background: #000;
    box-shadow: -1px 23px 48px -28px rgba(0, 0, 0, 0.75);
    transition: 0.5s all;
    //         background: rgba(69, 60, 41, 0.4);
    // filter: blur(32px);
    // border-radius: 6.88136px;
  }
}

.view-middle-content-left-card3 {
  background: $lighter-blue;
  background-color: #000;
  width: 100%;
  border-radius: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: -1px 10px 35px -28px rgba(0, 0, 0, 0.75);
  margin-bottom: 5%;
  .view-middle-content-left-card3-content {
    // padding: 50px;
    flex-direction: column;
    display: flex;
    margin-left: 2%;
    padding: 10px;
    height: 100%;
    .icon {
      font-size: 30px;
    }

    .view-moddle-content-left-card3-title {
      color: #fff;
      font-weight: 700;
      font-size: 16px;
      margin-bottom: 10px;
      text-align: left;
      .title {
        font-size: 10px;
      }
    }

    .view-moddle-content-left-card3-desc {
      color: #fff;
      font-weight: 500;
      font-size: 12px;
      line-height: 20.4px;
      text-align: left;
    }
  }
  &:hover {
    background: #000;
    box-shadow: -1px 23px 48px -28px rgba(0, 0, 0, 0.75);
    transition: 0.5s all;
    //         background: rgba(69, 60, 41, 0.4);
    // filter: blur(32px);
    // border-radius: 6.88136px;
  }
}
.active{
  display: block;
  margin: 2% 0;
}
.hidden{
  display: none;
}
.section-image {
  width: 100%;
  height: auto;
  
    object-fit: contain;
}
@media screen and (max-width: 544px) {
  .section-image {
    width: 100%;
    height: auto;
      object-fit: contain;
      max-height: 400px;
  }
  .heading-container {
    h1{
      padding: 3% 0;
      font-weight: bold;
font-size: 30px!important;
line-height: 57px;
text-align: center;
    }
}
.banner-logo {
  margin-top: 150px;
  margin-left: 15%;

  img {
    width: 100%;
  }
}
}


// @media screen and (max-width: 427px) {
//   .section-image{
//     width: 100%;
//     height: 100%;
//   }
// }

.highlight{
  font-weight: 900;
  a {
      text-decoration: none;
      font-weight: 900;
      color: #000;
    }
}

.animate {
  animation-name : mymove;
  animation-iteration-count : infinite;
  animation-direction : alternate;
  animation-duration : 2s;
}

@keyframes mymove {
  from {transform: translateY(0px);}
  to {transform: translateY(20px);}
}